var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-wrapper"},[_c('div',{staticClass:"page-wrapper-header",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("SALES_ORDERS.SALES_ORDERS_LIST"))+" ")])]),_c('div',{staticClass:"col-6 text-right"},[(_vm.$currentUserCan(_vm.$permissions.PERM_CREATE_SALES_ORDERS))?_c('base-button',{staticClass:"elite-button add",attrs:{"icon":"","size":"sm"},on:{"click":_vm.openSalesOrderCreateModal}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"far fa-plus-circle"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(" "+_vm._s(_vm.$t("SALES_ORDERS.ADD_SALES_ORDER"))+" ")])]):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_SALES_ORDERS))?_c('notification-subscription',{attrs:{"objectType":'sales-orders',"events":{
              CREATE: _vm.$t('NOTIFICATIONS.EVENT_CREATE'),
              UPDATE: _vm.$t('NOTIFICATIONS.EVENT_UPDATE'),
              DELETE: _vm.$t('NOTIFICATIONS.EVENT_DELETE'),
            }}}):_vm._e()],1)])]),_c('sales-order-list-table',{key:_vm.renderKey * 100,on:{"onViewSalesOrder":_vm.openSalesOrderViewModal,"onEditSalesOrder":_vm.openSalesOrderEditModal,"onDeleteSalesOrder":_vm.deleteSalesOrder}}),(_vm.isViewSalesOrderModalOpened)?_c('div',{key:_vm.renderKey * 200,staticClass:"resizable-wrapper",class:[_vm.isViewSalesOrderModalOpened ? 'show' : 'hide']},[_c('div',{directives:[{name:"resizable-modal",rawName:"v-resizable-modal",value:('SALES_ORDER'),expression:"'SALES_ORDER'"}],staticClass:"resizable-wrapper-content",on:{"onCloseModal":_vm.closeSalesOrderModal}},[_c('div',{staticClass:"resizable-wrapper-content-header"},[_c('div',{staticClass:"resizable-wrapper-content-header-left"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("SALES_ORDERS.VIEW_SALES_ORDER"))+" ")])]),_c('div',{staticClass:"resizable-wrapper-content-header-right"},[(
                _vm.openSalesOrderLoaded
                  ? _vm.openSalesOrder.items.length > 0 &&
                    _vm.openSalesOrder.status === _vm.ORDER_STATUS_DRAFT
                  : false
              )?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm","title":_vm.$t('SALES_ORDERS.VALIDATE')},on:{"click":function($event){return _vm.validateSalesOrder(_vm.openSalesOrder)}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"ni ni-check-bold"})])]):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ROLES))?_c('el-tooltip',{attrs:{"content":_vm.$t('COMMON.VIEW'),"placement":"top"}},[(
                  _vm.openSalesOrderLoaded
                    ? _vm.openSalesOrder.status == _vm.ORDER_STATUS_VALIDATED &&
                      _vm.openSalesOrder.invoicing_status !=
                        _vm.INVOICING_STATUS_INVOICED
                    : false
                )?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm","title":_vm.$t('SALES_ORDERS.ADD_INVOICE')},on:{"click":() => {
                    _vm.salesOrderInvoiceFormModalOpened = true;
                  }}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"ni ni-single-copy-04"})])]):_vm._e()],1):_vm._e(),(
                _vm.openSalesOrderLoaded
                  ? _vm.openSalesOrder.status == _vm.ORDER_STATUS_VALIDATED &&
                    _vm.openSalesOrder.delivery_status !=
                      _vm.DELIVERY_STATUS_DELIVERED
                  : false
              )?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm","title":_vm.$t('SALES_ORDERS.ADD_DELIVERY')},on:{"click":() => {
                  _vm.salesOrderDeliveryFormModalOpened = true;
                }}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fa fa-truck"})])]):_vm._e(),(_vm.canCancelSalesOrder)?_c('base-button',{attrs:{"type":"danger","icon":"","size":"sm","title":_vm.$t('SALES_ORDERS.CANCEL')},on:{"click":function($event){return _vm.cancelSalesOrder(_vm.openSalesOrder)}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"ni ni-fat-remove"})])]):_vm._e(),(_vm.openSalesOrder)?_c('notification-subscription',{attrs:{"objectType":'SalesOrders',"objectId":_vm.openSalesOrder.id,"events":{
                UPDATE: _vm.$t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: _vm.$t('NOTIFICATIONS.EVENT_DELETE'),
              }}}):_vm._e(),_c('base-dropdown',{attrs:{"title-classes":"btn dropdown-button","menu-on-right":"","has-toggle":false}},[_c('template',{slot:"title"},[_c('i',{staticClass:"fas fa-ellipsis-v"})]),(
                  _vm.openSalesOrder.status === _vm.ORDER_STATUS_DRAFT &&
                  _vm.$currentUserCan(_vm.$permissions.PERM_EDIT_SALES_ORDERS)
                )?_c('button',{staticClass:"edit",on:{"click":function($event){return _vm.openSalesOrderEditModal(_vm.openSalesOrder)}}},[_c('i',{staticClass:"fal fa-edit"}),_c('span',[_vm._v(_vm._s(_vm.$t("COMMON.EDIT")))])]):_vm._e(),(
                  _vm.openSalesOrder.status === _vm.ORDER_STATUS_DRAFT &&
                  _vm.$currentUserCan(_vm.$permissions.PERM_DELETE_SALES_ORDERS)
                )?_c('button',{staticClass:"delete",on:{"click":function($event){return _vm.deleteSalesOrder(_vm.openSalesOrder)}}},[_c('i',{staticClass:"fal fa-trash-alt"}),_c('span',[_vm._v(_vm._s(_vm.$t("COMMON.DELETE")))])]):_vm._e()],2),_c('button',{staticClass:"close",on:{"click":_vm.closeSalesOrderModal}},[_c('i',{staticClass:"fal fa-times"})])],1)]),_c('div',{staticClass:"resizable-wrapper-content-body"},[(_vm.openSalesOrder)?_c('view-sales-order-page',{attrs:{"salesOrderId":_vm.openSalesOrder.id},on:{"salesOrderLoaded":(salesOrder) => {
                _vm.openSalesOrder = salesOrder;
                _vm.openSalesOrderLoaded = true;
              }}}):_vm._e()],1)])]):_vm._e(),(_vm.isEditSalesOrderModalOpened)?_c('div',{key:_vm.renderKey * 300,staticClass:"resizable-wrapper",class:[_vm.isEditSalesOrderModalOpened ? 'show' : 'hide']},[_c('div',{directives:[{name:"resizable-modal",rawName:"v-resizable-modal",value:('SALES_ORDER'),expression:"'SALES_ORDER'"}],staticClass:"resizable-wrapper-content",on:{"onCloseModal":_vm.closeSalesOrderModal}},[_c('div',{staticClass:"resizable-wrapper-content-header"},[_c('div',{staticClass:"resizable-wrapper-content-header-left"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("SALES_ORDERS.EDIT_SALES_ORDER"))+" ")])]),_c('div',{staticClass:"resizable-wrapper-content-header-right"},[_c('button',{staticClass:"close",on:{"click":function($event){return _vm.openSalesOrderViewModal(_vm.openSalesOrder)}}},[_c('i',{staticClass:"fal fa-times"})])])]),_c('div',{staticClass:"resizable-wrapper-content-body"},[(_vm.openSalesOrder)?_c('edit-sales-order-page',{attrs:{"salesOrderId":_vm.openSalesOrder.id},on:{"onViewSalesOrder":_vm.openSalesOrderViewModal}}):_vm._e()],1)])]):_vm._e(),(_vm.isAddSalesOrderModalOpened)?_c('div',{key:_vm.renderKey * 400,staticClass:"resizable-wrapper",class:[_vm.isAddSalesOrderModalOpened ? 'show' : 'hide']},[_c('div',{directives:[{name:"resizable-modal",rawName:"v-resizable-modal",value:('SALES_ORDER'),expression:"'SALES_ORDER'"}],staticClass:"resizable-wrapper-content",on:{"onCloseModal":_vm.closeSalesOrderModal}},[_c('div',{staticClass:"resizable-wrapper-content-header"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("SALES_ORDERS.ADD_SALES_ORDER"))+" ")]),_c('button',{staticClass:"close",on:{"click":_vm.closeSalesOrderModal}},[_c('i',{staticClass:"fal fa-times"})])]),_c('div',{staticClass:"resizable-wrapper-content-body"},[_c('add-sales-order-page',{on:{"onViewSalesOrder":_vm.openSalesOrderViewModal}})],1)])]):_vm._e()],1),(
      _vm.openSalesOrderLoaded
        ? !_vm.openSalesOrder.deliveryItemsState.orderDelivered
        : false
    )?_c('sales-order-view-add-delivery-form',{attrs:{"salesOrder":_vm.openSalesOrder,"showModal":_vm.salesOrderDeliveryFormModalOpened},on:{"closeSalesOrderDeliveryForm":() => {
        _vm.salesOrderDeliveryFormModalOpened = false;
      }}}):_vm._e(),(
      _vm.openSalesOrderLoaded
        ? !_vm.openSalesOrder.invoicingItemsState.orderInvoiced
        : false
    )?_c('sales-order-view-add-invoice-form',{attrs:{"salesOrder":_vm.openSalesOrder,"showModal":_vm.salesOrderInvoiceFormModalOpened},on:{"closeSalesOrderInvoiceForm":() => {
        _vm.salesOrderInvoiceFormModalOpened = false;
      }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }